import React, {createContext, useState} from 'react';

const GlobalContext = createContext({
  additionalPackages: [],
  setAdditionalPackages: () => {},
})

function GlobalProvider({children}) {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  // const setGlobalAdditionalPackages = (packages = []) => {
  //   setAdditionalPackages(packages)
  // }
  return (
    <GlobalContext.Provider value={{
      isVideoPlaying,
      setIsVideoPlaying
    }}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContext;
export { GlobalProvider }
