// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brands-fimes-org-tsx": () => import("./../../../src/pages/brands/fimes_org.tsx" /* webpackChunkName: "component---src-pages-brands-fimes-org-tsx" */),
  "component---src-pages-brands-mk-org-tsx": () => import("./../../../src/pages/brands/mk_org.tsx" /* webpackChunkName: "component---src-pages-brands-mk-org-tsx" */),
  "component---src-pages-brands-mussi-org-tsx": () => import("./../../../src/pages/brands/mussi_org.tsx" /* webpackChunkName: "component---src-pages-brands-mussi-org-tsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/about_us.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-blog-item-tsx": () => import("./../../../src/templates/blog_item.tsx" /* webpackChunkName: "component---src-templates-blog-item-tsx" */),
  "component---src-templates-blog-list-media-tsx": () => import("./../../../src/templates/blog_list_media.tsx" /* webpackChunkName: "component---src-templates-blog-list-media-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog_list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-partner-item-tsx": () => import("./../../../src/templates/partner_item.tsx" /* webpackChunkName: "component---src-templates-partner-item-tsx" */),
  "component---src-templates-press-tsx": () => import("./../../../src/templates/press.tsx" /* webpackChunkName: "component---src-templates-press-tsx" */),
  "component---src-templates-project-item-tsx": () => import("./../../../src/templates/project_item.tsx" /* webpackChunkName: "component---src-templates-project-item-tsx" */),
  "component---src-templates-project-list-tsx": () => import("./../../../src/templates/project_list.tsx" /* webpackChunkName: "component---src-templates-project-list-tsx" */)
}

